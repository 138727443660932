import "./app.css";
import { Helmet } from "react-helmet";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Landing } from "./pages/Landing";
import { Chat } from "./pages/Chat";
import { useEffect, useState } from "react";
import { getAccount } from "./hooks/getAccount";
import { setGlobalState } from "./state/state";
import PaymentSuccess from "./pages/PaymentCompletion";
import Logo from "./components/Logo/Logo";
import Profile from "./components/Profile/Profile";
import { updateAccountSettings } from "./hooks/updateAccountSettings";

const App = () => {
  const [authToken, setAuthToken] = useState();
  const [account, setAccount] = useState();
  const [profileVisible, setProfileVisible] = useState(false);
  const [isRetrievingAccount, setIsRetrievingAccount] = useState();

  const saveAuthToken = (authorization) => {
    if (!authorization) {
      localStorage.removeItem("authorization");
      setAuthToken();
      return;
    }
    localStorage.setItem("authorization", authorization);
    setAuthToken(authorization);
    setGlobalState("authToken", authorization);
  };

  const handleLogOut = () => {
    saveAuthToken();
    setAccount();
    window.location.reload(true);
  };

  useEffect(() => {
    //Handle retrieving auth token from localstorage
    if (authToken === undefined) {
      const authorization = localStorage.getItem("authorization");
      if (authorization) {
        setAuthToken(authorization);
        setGlobalState("authToken", authorization);
      } else {
        setAuthToken(null);
      }
    }

    //Handle account retrieval
    if (authToken && !account && !isRetrievingAccount) {
      setGlobalState("handleGetAccount", () => handleGetAccount);
      setIsRetrievingAccount(true);
      handleGetAccount();
    }
  });

  const handleGetAccount = async () => {
    console.log("Get account called");
    const res = await getAccount(authToken);
    if (res.error) {
      saveAuthToken();
      setAccount();
      window.location.reload(true);
      return;
    }
    const account = res.body.account;
    setGlobalState("account", account);
    setAccount(res.body.account);
    if (account.settings) {
      setGlobalState("current_voice", account.settings.voice);
      setGlobalState("current_agent", account.settings.agent);
    }
    setIsRetrievingAccount(false);
  };

  const handleUpdateSettings = async (settingName, settingValue) => {
    const res = await updateAccountSettings(
      authToken,
      settingName,
      settingValue
    );
    if (res.error) {
      saveAuthToken();
      setAccount();
      window.location.reload(true);
      return;
    }
  };

  const globals = {
    handleLogOut,
  };

  setGlobalState("handleLogOut", () => handleLogOut);
  setGlobalState("handleUpdateSettings", () => handleUpdateSettings);

  return (
    <div className="w-screen h-screen">
      <Helmet>
        <style>
          {`body {
            	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
              background-size: 400% 400%;
              animation: gradient 15s ease infinite;,
          }
          
          @keyframes gradient {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
          `}
        </style>
      </Helmet>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Landing authToken={authToken} saveAuthToken={saveAuthToken} />
            }
          />
          <Route
            path="/chat"
            element={
              <Chat
                globals={globals}
                authToken={authToken}
                account={account}
                setProfileVisible={setProfileVisible}
              />
            }
          />
          <Route path="/$/:payment_status" element={<PaymentSuccess />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      <Logo />
      <Profile visible={profileVisible} setVisible={setProfileVisible} />
    </div>
  );
};

export default App;
