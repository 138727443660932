import axios from "axios";

export const createAccount = async (method, phone, email, name) => {
  const res = {
    error: false,
    statusCode: undefined,
    body: undefined,
  };
  const config = {
    method: "POST",
    url: "https://sgh82xukyg.execute-api.us-west-1.amazonaws.com/Prod/account/signup",
    data: JSON.stringify({
      method,
      phone,
      phone_country: "1",
      email,
      name,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios(config);
    res.statusCode = response.status;
    res.body = response.data;
  } catch (error) {
    console.log("Error creating account:", error);
    if (error.response) {
      console.log("Response data:", JSON.stringify(error.response.data));
    }
    res.error = true;
    res.statusCode = error.response?.status;
    res.body = error.response?.data;
  }
  return res;
};
