import { useState } from "react";
import { TfiClose } from "react-icons/tfi";
import { setGlobalState, useGlobalState } from "../../state/state";
import { cancelSubscription } from "../../hooks/cancelSubscription";
import { handle_stripe_payment } from "../../hooks/handleStripePayment";
import { AiFillCheckCircle } from "react-icons/ai";
import ProfilePicture from "./ProfilePicture/ProfilePicture";
import "./styles.css";

const Profile = ({ visible, setVisible }) => {
  const [underline, _underline] = useState(false);
  const [account] = useGlobalState("account");
  const [handleLogOut] = useGlobalState("handleLogOut");
  const [handleUpdateSettings] = useGlobalState("handleUpdateSettings");
  const [$current_voice] = useGlobalState("current_voice");
  const [$current_agent] = useGlobalState("current_agent");

  const is_free_tier = account?.subscription?.tier === "free";
  const is_standard_tier = account?.subscription?.tier === "standard";

  return (
    window.location.pathname !== "/" && (
      <>
        {!visible && (
          <ProfilePicture
            onClick={() => {
              {
                setVisible(true);
                setGlobalState("showing_about", false);
                setGlobalState("showing_profile", true);
              }
            }}
            onMouseEnter={() => _underline(true)}
            onMouseLeave={() => _underline(false)}
          />
        )}
        <div
          className="bg-white h-[3px] w-full duration-300 top-0 right-0 fixed"
          style={{ width: underline ? "100%" : 0 }}
        ></div>
        {visible && (
          <>
            <div className="h-screen w-screen bg-black slideInFromRight fixed top-0" />
            <div className="fixed top-0 left-0 text-white w-screen h-screen fadeIn bg-black bg-opacity-80 pt-[15vh] px-6 md:px-10 lg:px-16 xl:px-[22vw] duration-300 text-lg flex flex-col justify-center items-center pb-[5vh]">
              <div class={`absolute top-9 right-6 duration-300 scaleIn ml-1`}>
                <TfiClose
                  size={30}
                  onClick={() => {
                    setVisible(false);
                    _underline(false);
                    setGlobalState("showing_profile", false);
                  }}
                  style={{ color: "white", cursor: "pointer" }}
                />
              </div>
              <div className="text-2xl font-semibold fadeIn">
                Welcome Back, {account?.name}
              </div>

              {is_free_tier && <FreeTier />}
              {account?.views?.show_girlfriend_testing && (
                <>
                  <div className="text-gray-400 text-xl font-semibold lg:mb-2 mt-8 animate-[fadeInAnimation_3s_ease]">
                    Personas
                  </div>
                  <div
                    className="relative text-gray-300 text-xl w-full md:w-[40vw] lg:w-[30vw] h-12 rounded-full border-gray-400 border-[1.5px] font-semibold mt-2 justify-center items-center flex hover:scale-[1.02] duration-300 cursor-pointer animate-[fadeInAnimation_3s_ease]"
                    onClick={() => {
                      setGlobalState("current_agent", "general");
                      handleUpdateSettings("agent", "general");
                    }}
                  >
                    🗣️ General
                    {$current_agent === "general" && (
                      <AiFillCheckCircle className="absolute right-4 fadeIn text-green-400" />
                    )}
                  </div>
                  <div
                    className="relative text-gray-300 text-xl w-full md:w-[40vw] lg:w-[30vw] h-12 rounded-full border-gray-400 border-[1.5px] font-semibold mt-2 justify-center items-center flex hover:scale-[1.02] duration-300 cursor-pointer animate-[fadeInAnimation_3s_ease]"
                    onClick={() => {
                      setGlobalState("current_agent", "girlfriend");
                      handleUpdateSettings("agent", "girlfriend");
                    }}
                  >
                    🗣️ Girlfriend
                    {$current_agent === "girlfriend" && (
                      <AiFillCheckCircle className="absolute right-4 fadeIn text-green-400" />
                    )}
                  </div>
                  {$current_agent === "general" && (
                    <>
                      <div
                        className="text-gray-400 text-xl font-semibold lg:mb-2 mt-8 animate-[fadeInAnimation_3s_ease]"
                        style={{
                          opacity: $current_agent === "general" ? 1 : 0,
                        }}
                      >
                        Voices
                      </div>
                      <div
                        className="relative text-gray-300 text-xl w-full md:w-[40vw] lg:w-[30vw] h-12 rounded-full border-gray-400 border-[1.5px] font-semibold mt-2 justify-center items-center flex hover:scale-[1.02] duration-300 animate-[fadeInAnimation_3s_ease] cursor-pointer"
                        style={{
                          opacity: $current_agent === "general" ? 1 : 0,
                        }}
                        onClick={() => {
                          setGlobalState("current_voice", "male");
                          handleUpdateSettings("voice", "male");
                        }}
                      >
                        🗣️ David
                        {$current_voice === "male" && (
                          <AiFillCheckCircle className="absolute right-4 fadeIn text-green-400" />
                        )}
                      </div>
                      <div
                        className="relative text-gray-300 text-xl w-full md:w-[40vw] lg:w-[30vw] h-12 rounded-full border-gray-400 border-[1.5px] font-semibold mt-2 justify-center items-center flex hover:scale-[1.02] duration-300 animate-[fadeInAnimation_3s_ease] cursor-pointer"
                        style={{
                          opacity: $current_agent === "general" ? 1 : 0,
                        }}
                        onClick={() => {
                          setGlobalState("current_voice", "female");
                          handleUpdateSettings("voice", "female");
                        }}
                      >
                        🗣️ Amelia
                        {$current_voice === "female" && (
                          <AiFillCheckCircle className="absolute right-4 fadeIn text-green-400" />
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
              {!account?.views?.show_girlfriend_testing && (
                <>
                  <div
                    className="text-gray-400 text-xl font-semibold lg:mb-2 mt-8 animate-[fadeInAnimation_3s_ease]"
                    style={{
                      opacity: $current_agent === "general" ? 1 : 0,
                    }}
                  >
                    Voices
                  </div>
                  <div
                    className="relative text-gray-300 text-xl w-full md:w-[40vw] lg:w-[30vw] h-12 rounded-full border-gray-400 border-[1.5px] font-semibold mt-2 justify-center items-center flex hover:scale-[1.02] duration-300 animate-[fadeInAnimation_3s_ease] cursor-pointer"
                    style={{
                      opacity: $current_agent === "general" ? 1 : 0,
                    }}
                    onClick={() => {
                      setGlobalState("current_voice", "male");
                    }}
                  >
                    🗣️ David
                    {$current_voice === "male" && (
                      <AiFillCheckCircle className="absolute right-4 fadeIn text-green-400" />
                    )}
                  </div>
                  <div
                    className="relative text-gray-300 text-xl w-full md:w-[40vw] lg:w-[30vw] h-12 rounded-full border-gray-400 border-[1.5px] font-semibold mt-2 justify-center items-center flex hover:scale-[1.02] duration-300 animate-[fadeInAnimation_3s_ease] cursor-pointer"
                    style={{
                      opacity: $current_agent === "general" ? 1 : 0,
                    }}
                    onClick={() => {
                      setGlobalState("current_voice", "female");
                    }}
                  >
                    🗣️ Amelia
                    {$current_voice === "female" && (
                      <AiFillCheckCircle className="absolute right-4 fadeIn text-green-400" />
                    )}
                  </div>
                </>
              )}

              {is_standard_tier && <StandardTier />}
              <p
                className="hover:scale-[1.05] duration-200"
                style={{
                  fontSize: "18px",
                  position: "absolute",
                  bottom: 20,
                  right: 20,
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#FF6347",
                }}
                onClick={handleLogOut}
              >
                Log out
              </p>
            </div>
          </>
        )}
      </>
    )
  );
};

const FreeTier = () => {
  const [account] = useGlobalState("account");

  return (
    <div className="flex flex-col w-full">
      <div className="text-gray-500 text-md text-center">
        Keep the conversation going
      </div>
      <div className="w-full rounded-[1rem] shadow-lg mt-2 flex flex-col justify-between text-white">
        <div className="flex flex-col items-center px-2">
          <div
            className="self-center text-4xl font-bold mt-4"
            style={{ overflowX: "visible", overflowY: "visible" }}
          >
            ${account?.pricing?.monthly?.value} monthly
          </div>
          <div className="text-md mt-6">
            <span className="text-green-400">✧</span> Unlimited Interactions
          </div>
          <div className="text-md">
            <span className="text-green-400">✧</span> Supercharged memory
          </div>
          <div className="text-md">
            <span className="text-green-400">✧</span> More voices (soon!)
          </div>
          <div className="text-md">
            <span className="text-green-400">✧</span> Persona customization
            (soon!)
          </div>
          <div
            className="w-full md:w-[40vw] lg:w-[30vw] h-12 rounded-full border-green-400 border-[1.5px] mt-4 md:mt-6 lg:mt-10 hover:scale-[1.02] duration-300 text-green-500 font-semibold text-md justify-center items-center flex"
            onClick={() => handle_stripe_payment(account?.account_id)}
          >
            Subscribe
          </div>
        </div>
      </div>
    </div>
  );
};

const StandardTier = () => {
  const [account] = useGlobalState("account");
  const [authToken] = useGlobalState("authToken");
  const [handleGetAccount] = useGlobalState("handleGetAccount");
  const [confirm_cancel, _confirm_cancel] = useState(false);

  const handle_stripe_cancel = async () => {
    const res = await cancelSubscription(authToken);
    if (res.error) {
      //TODO: Error handle cancellations
      alert(
        "Oops, there was an issue cancelling your subscription. Please reach out to us and we'll sort it out!"
      );
    }

    handleGetAccount();
  };

  const parse_date = (dateString) => {
    const date = new Date(dateString);

    const month = date.toLocaleDateString("en-US", { month: "long" });
    const day = date.toLocaleDateString("en-US", { day: "numeric" });

    return `${month} ${day}`;
  };

  return (
    <div className="rounded-[1rem] shadow-lg mt-2 flex flex-col justify-between text-white">
      <div className="flex flex-col items-center space-y-4 p-2 mt-4">
        <div className="text-gray-400 text-xl font-semibold lg:mb-2">
          Coming Soon!
        </div>
        <div className="text-gray-500 text-xl w-[90vw] md:w-[40vw] lg:w-[30vw] h-12 rounded-full border-gray-400 border-[1.5px] font-semibold mt-2 justify-center items-center flex hover:scale-[1.02] duration-300">
          🧠 Memories
        </div>
        <div className="text-gray-500 text-xl w-[90vw] md:w-[40vw] lg:w-[30vw] h-12 rounded-full border-gray-400 border-[1.5px] font-semibold mt-2 justify-center items-center flex hover:scale-[1.02] duration-300">
          ✨ Personas
        </div>
        <div className="text-gray-500 text-xl w-[90vw] md:w-[40vw] lg:w-[30vw] h-12 rounded-full border-gray-400 border-[1.5px] font-semibold mt-2 justify-center items-center flex hover:scale-[1.02] duration-300">
          🔍 Analyze{" "}
        </div>
        {confirm_cancel ? (
          <div
            className="w-full h-12 rounded-full mt-4 md:mt-6 lg:mt-10 hover:scale-[1.02] duration-300 text-red-500 font-semibold text-md justify-center items-center flex"
            onClick={handle_stripe_cancel}
          >
            Confirm Cancel
          </div>
        ) : (
          <div
            className="w-full h-12 rounded-full mt-4 md:mt-6 lg:mt-10 hover:scale-[1.02] duration-300 text-white-500 font-semibold text-md justify-center items-center flex"
            onClick={() => _confirm_cancel(true)}
          >
            Cancel Subscription
          </div>
        )}
        <div className="text-sm text-gray-500 text-md">
          Next Billing {parse_date(account?.subscription?.renews_at)}{" "}
        </div>
      </div>
    </div>
  );
};

export default Profile;
