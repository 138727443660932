import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { handle_stripe_payment } from "../../hooks/handleStripePayment";
import { useGlobalState } from "../../state/state";

const FooterRight = ({ total, remaining, setProfileVisible }) => {
  const [showAmount, _showAmount] = useState(false);
  const [account] = useGlobalState("account");
  const [show_no_more_chats, _show_no_more_chats] =
    useGlobalState("show_no_more_chats");
  const overfilled = remaining > total;
  const is_free_tier = account?.subscription?.tier === "free";

  useEffect(() => {
    _show_no_more_chats(remaining <= 0);
  }, []);

  return (
    <>
      {!is_free_tier && (
        <div
          className="relative"
          onMouseEnter={() => {
            _showAmount(true);
          }}
          onMouseLeave={() => {
            _showAmount(false);
          }}
          onClick={() => {
            if (remaining > 0) return;
            _show_no_more_chats(true);
          }}
        >
          {(showAmount || remaining <= 0) && (
            <div className="fixed bottom-10 right-6 text-white text-lg fadeIn">
              {Math.min(remaining, total)}
              {overfilled && "+"} Chats
            </div>
          )}
          <div
            className={`fixed bottom-10 right-6 w-[20vw] h-[3px] bg-slate-300 rounded-full scaleIn`}
          />
          <div
            className="fixed bottom-10 right-6 h-[3px] bg-white rounded-full transition-300 scaleIn"
            style={{ width: (Math.min(remaining, total) / total) * 20 + "vw" }}
          />
        </div>
      )}
      <Modal visible={show_no_more_chats}>
        <div className="flex flex-col items-center p-2">
          <div className="text-center text-white">
            Keep the conversation going!
          </div>
          <div className="text-center text-gray-400">You're out of chats</div>
          <div
            className="h-12 min-w-full rounded-full border-green-400 border-[1.5px] mt-4 md:mt-6 lg:mt-10 hover:scale-[1.02] duration-300 text-green-500 font-semibold text-md justify-center items-center flex"
            onClick={
              () => {
                setProfileVisible(true);
              }

              /*handle_stripe_payment(account?.account_id)*/
            }
          >
            Keep Chatting
          </div>
          <div
            className="h-12 min-w-full rounded-full border-gray-400 border-[1.5px] mt-4 hover:scale-[1.02] duration-300 text-white font-semibold text-md justify-center items-center flex"
            onClick={() => _show_no_more_chats(false)}
          >
            Maybe Later
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FooterRight;
