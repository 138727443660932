import { VscAccount } from "react-icons/vsc";
import { useGlobalState } from "../../../state/state";

const ProfilePicture = ({ onClick, onMouseEnter, onMouseLeave }) => {
  const [showing_about] = useGlobalState("showing_about");

  return (
    <div
      className="fixed top-8 right-6 rounded-full scaleIn"
      onClick={onClick}
      style={{ cursor: "pointer", opacity: showing_about ? 0 : 1 }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <VscAccount size={35} color="white" />
    </div>
  );
};

export default ProfilePicture;
