import "./styles.css";

const Modal = ({ visible, _visible, children }) => {
  return (
    visible && (
      <div
        className="fixed top-0 left-0 w-screen h-screen fadeIn bg-black bg-opacity-50 flex justify-center items-center px-4 lg:px-[20vw] z-999 duration-300"
        onClick={() => {
          if (_visible) _visible(false);
        }}
      >
        <div
          className="fixed top-0 left-0 w-screen h-screen fadeIn bg-black bg-opacity-70 flex justify-center items-center px-4 lg:px-[20vw] duration-300"
          onClick={() => {
            if (_visible) _visible(false)
          }}>
          {children}
        </div>
      </div>
    )
  );
};

export default Modal;
