import { createGlobalState } from "react-hooks-global-state";

const { setGlobalState, useGlobalState } = createGlobalState({
  account: null,
  authToken: null,
  chatMessages: null,
  current_voice: "male",
  current_agent: "general",
  showing_about: false,
  showing_profile: false,
  show_no_more_chats: false,
  handleGetAccount: () => {},
  handleLogOut: () => {},
  handleUpdateSettings: () => {},
  display_error: false,
  // ... other global state variables
});

export { useGlobalState, setGlobalState };
