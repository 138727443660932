import { useParams } from "react-router-dom";
import Modal from "../components/Modal/Modal";


const PaymentCompletion = () => {

    const { payment_status } = useParams();

    return (
        <div className="background" style={{ justifyContent: "center" }}>
            {payment_status === "success" &&
                <Modal visible={true}>
                    <div className="flex flex-col items-center p-2">
                        <div className="text-center text-white">Payment Successful!</div>
                        {/* button that takes user back to /chat */}
                        <div className="w-full h-12 rounded-full border-green-400 border-[1.5px] mt-4 hover:scale-[1.02] duration-300 text-green-500 font-semibold text-md justify-center items-center flex" onClick={() => window.location = "/chat"}>
                            Start chatting!
                        </div>
                    </div>
                </Modal>
            }
            {payment_status === "error" &&
                <Modal visible={true}>
                    <div className="flex flex-col items-center p-2">
                        <div className="text-center text-white">Payment Failed!</div>
                        <div className="w-full h-12 rounded-full border-gray-400 border-[1.5px] mt-4 hover:scale-[1.02] duration-300 text-white font-semibold text-md justify-center items-center flex" onClick={() => window.location = "/chat"}>
                            Got it!
                        </div>
                    </div>
                </Modal>
            }
        </div>

    );
}

export default PaymentCompletion
    ;