import { generateChatResponse } from "../../hooks/generateChatResponse";
import { handleGetChatHistory } from "../../hooks/handleGetChatHistory";
import { useGlobalState } from "../../state/state";
import Loader from "../Loader/Loader";

const HeyBtnDesktop = ({
  navigate,
  loading,
  setLoading,
  sourceBufferRef,
  mediaSource,
  isPlaying,
}) => {
  const [account] = useGlobalState("account");
  const [authToken] = useGlobalState("authToken");
  const [current_voice] = useGlobalState("current_voice");
  const [, _show_no_more_chats] = useGlobalState("show_no_more_chats");
  const [handleGetAccount] = useGlobalState("handleGetAccount");
  const [current_agent] = useGlobalState("current_agent");

  const handleStart = async () => {
    if (account?.subscription?.chat_tokens === 0) {
      _show_no_more_chats(true);
      return;
    }

    setLoading(true);

    let url =
      "https://uekilqvn4ypaygdvhinwfqgvqy0umdgf.lambda-url.us-west-1.on.aws/";
    if (current_agent === "girlfriend") {
      url =
        "https://qh2rz2jztirzpxkiyb3ei7kvte0fkgtv.lambda-url.us-west-1.on.aws/";
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: authToken,
      },
      body: JSON.stringify({
        chat_type: "intro",
        voice_type: current_voice,
      }),
    });

    let waitingCount = 0;
    const reader = response.body.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        while (waitingCount > 0 || sourceBufferRef.current.updating) {
          await new Promise((r) => setTimeout(r, 100));
        }
        handleGetAccount();
        mediaSource.endOfStream();
        break;
      }
      let waitForUpdate = false;
      if (sourceBufferRef.current.updating) {
        waitingCount++;
        waitForUpdate = true;
      }
      while (sourceBufferRef.current.updating) {
        await new Promise((r) => setTimeout(r, 100));
      }
      if (waitForUpdate) waitingCount--;
      sourceBufferRef.current.appendBuffer(value);
    }
  };

  return (
    <>
      {loading && <Loader showMessages={!isPlaying} />}
      {!loading && (
        <button className="startButton" onClickCapture={handleStart}>
          Hey!
        </button>
      )}
    </>
  );
};

export default HeyBtnDesktop;
