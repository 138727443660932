import axios from "axios";

export const getChatHistory = async (Authorization) => {
  const res = {
    error: false,
    statusCode: undefined,
    body: undefined,
  };
  const config = {
    method: "GET",
    url: "https://sgh82xukyg.execute-api.us-west-1.amazonaws.com/Prod/chat/history",
    headers: {
      "Content-Type": "application/json",
      Authorization,
    },
  };

  try {
    const response = await axios(config);
    res.statusCode = response.status;
    res.body = response.data;
  } catch (error) {
    console.log("Error verifying code:", error);
    if (error.response) {
      console.log("Response data:", JSON.stringify(error.response.data));
    }
    res.error = true;
    res.statusCode = error.response?.status;
    res.body = error.response?.data;
  }
  return res;
};
