import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "../components/PhoneInput/PhoneInput";
import { VerificationCodeInput } from "../components/VerificationCodeInput/VerificationCodeInput";
import { createAccount } from "../hooks/createAccount";
import { useRef } from "react";

import { sendCode } from "../hooks/sendCode";
import useMouseWiggle from "../hooks/useMouseWiggle";
import { verifyCode } from "../hooks/verifyCode";
import { TextInput } from "../components/TextInput/TextInput";

export const Landing = ({ saveAuthToken, authToken }) => {
  let [screenState, setScreenState] = useState("login");
  const [loading, setLoading] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, _userEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [signInMethod, _signInMethod] = useState("phone");
  const navigate = useNavigate();
  const createAccountBtnRef = useRef();

  const isValidEmail = (email) => {
    return /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/.test(email);
  };

  const handleSendCode = async (silent) => {
    if (signInMethod === "phone" && userPhone.length < 10) return;
    if (signInMethod === "email" && !isValidEmail(userEmail)) return;
    if (!silent) setLoading(true);
    const res = await sendCode(signInMethod, userPhone, userEmail);
    if (!silent) setLoading(false);
    if (res.statusCode === 200) {
      setScreenState("verify_code");
    } else if (res.statusCode === 400 && res.body === "Account not found") {
      setScreenState("ask_for_name");
    } else {
      alert("Oops, something went wrong. We're doing our best to fix it!");
    }
  };

  const handleCreateAccount = async () => {
    if (signInMethod === "phone" && userPhone.length < 10) return;
    if (signInMethod === "email" && !isValidEmail(userEmail)) return;
    setScreenState("verify_code");
    const res = await createAccount(
      signInMethod,
      userPhone,
      userEmail,
      userName
    );
    if (res.statusCode === 200) {
      handleSendCode(true);
    } else {
      alert("Oops, something went wrong. We're doing our best to fix it!");
    }
  };

  const handleVerifyCode = async () => {
    if (verificationCode.length !== 4) return;
    setLoading(true);
    const res = await verifyCode(
      signInMethod,
      userPhone,
      userEmail,
      verificationCode
    );
    setLoading(false);
    if (res.statusCode === 200) {
      saveAuthToken(res.body.token);
      navigate("/chat");
    } else if (res.statusCode === 400 && res.body === "Invalid code") {
      alert("Invalid code");
    } else {
      alert("Oops, something went wrong. We're doing our best to fix it!");
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (loading) return;
        event.preventDefault();
        if (screenState === "login" && userPhone.length === 10) {
          handleSendCode();
        } else if (screenState === "ask_for_name" && userName.length > 1) {
          handleCreateAccount();
        } else if (
          screenState === "verify_code" &&
          verificationCode.length === 4
        ) {
          handleVerifyCode();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });

  useEffect(() => {
    if (authToken) {
      navigate("/chat");
    }
  });

  const transformStyle = useMouseWiggle();

  return (
    <div
      className="flex flex-col items-center justify-center h-fit"
      style={{ transform: transformStyle }}
    >
      {screenState === "login" && !loading && (
        <>
          <p
            className="text-white text-4xl font-bold"
            style={{
              marginTop: "15vh",
              textAlign: "center",
              animation: "fadeInAnimation ease 3s",
            }}
          >
            We haven't met before...
          </p>
          {signInMethod === "phone" && (
            <>
              <p
                className="text-white text-xl font-light"
                style={{
                  marginTop: "10px",
                  marginBottom: "8vh",
                  textAlign: "center",
                  animation: "fadeInAnimation ease 3s",
                }}
              >
                What's your phone number?
              </p>
              <PhoneInput setUserInput={setUserPhone} />
              {userPhone.length === 10 && (
                <>
                  <button
                    className="basicButton"
                    style={{
                      marginTop: "30px",
                    }}
                    onClick={handleSendCode}
                  >
                    Let's Talk
                  </button>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "rgba(255, 255, 255, 0.8)",
                      margin: "15px 0px 0px 0px",
                      animation: "fadeInAnimation ease 3s",
                    }}
                  >
                    By clicking "Let's Talk", you opt into receiving a
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "rgba(255, 255, 255, 0.8)",
                      margin: "2px 0px 0px 0px",
                      animation: "fadeInAnimation ease 3s",
                    }}
                  >
                    one-time SMS message containing a verification code
                  </p>
                </>
              )}
              {userPhone.length < 7 && (
                <button
                  className="basicButton"
                  style={{
                    marginTop: "50px",
                  }}
                  onClick={() => _signInMethod("email")}
                >
                  Use Email Instead
                </button>
              )}
            </>
          )}
          {signInMethod === "email" && (
            <>
              <p
                className="text-white text-xl font-light"
                style={{
                  marginTop: "10px",
                  marginBottom: "8vh",
                  textAlign: "center",
                  animation: "fadeInAnimation ease 3s",
                }}
              >
                What's your email?
              </p>
              <TextInput setValue={_userEmail} onEnter={handleSendCode} />
              {isValidEmail(userEmail) && (
                <>
                  <button
                    className="basicButton"
                    style={{
                      marginTop: "30px",
                    }}
                    onClick={handleSendCode}
                  >
                    Let's Talk
                  </button>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "rgba(255, 255, 255, 0.8)",
                      margin: "15px 0px 0px 0px",
                      animation: "fadeInAnimation ease 3s",
                    }}
                  >
                    By clicking "Let's Talk", you opt into receiving a
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "rgba(255, 255, 255, 0.8)",
                      margin: "2px 0px 0px 0px",
                      animation: "fadeInAnimation ease 3s",
                    }}
                  >
                    one-time SMS message containing a verification code
                  </p>
                </>
              )}
              {!isValidEmail(userEmail) && userEmail.length < 5 && (
                <button
                  className="basicButton"
                  style={{
                    marginTop: "50px",
                  }}
                  onClick={() => _signInMethod("phone")}
                >
                  Use Phone # Instead
                </button>
              )}
            </>
          )}
        </>
      )}
      {screenState === "ask_for_name" && !loading && (
        <>
          <p
            className="text-white text-2xl font-bold"
            style={{ marginBottom: "5vh", marginTop: "20vh" }}
          >
            What's your name?
          </p>
          <input
            className="border-b-2 border-white border-solid text-white text-2xl bg-transparent outline-none text-center duration-100 box-border min-w-[10px] max-w-full"
            autoFocus
            aria-multiline="true"
            onChange={(e) => {
              e.target.style.width = "auto";
              e.target.style.width = e.target.scrollWidth + "px";
              setUserName(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                handleCreateAccount();
              }
            }}
          />
          {userName.length > 1 && (
            <button
              ref={createAccountBtnRef}
              className="basicButton"
              style={{ marginTop: "50px" }}
              onClick={() => {
                handleCreateAccount();
                if (createAccountBtnRef.current) {
                  createAccountBtnRef.current.setAttribute(
                    "disabled",
                    "disabled"
                  );
                  createAccountBtnRef.current.style.display = "none";
                }
              }}
            >
              That's me!
            </button>
          )}
        </>
      )}
      {screenState === "verify_code" && !loading && (
        <>
          <p
            className="title"
            style={{
              marginTop: "20vh",
            }}
          >
            I just {signInMethod === "phone" ? "texted" : "emailed"} you a code
          </p>
          <p
            className="text-white opacity-70 text-xl"
            style={{
              marginBottom: "4vh",
              paddingInline: "6vw",
              opacity: 0.8,
              animation: "fadeInAnimation ease 3s",
            }}
          >
            Please enter it below
          </p>
          <VerificationCodeInput setUserInput={setVerificationCode} />
          {verificationCode.length === 4 && (
            <button
              className="basicButton"
              style={{ marginTop: "40px" }}
              onClick={handleVerifyCode}
            >
              Let's start!
            </button>
          )}
        </>
      )}
    </div>
  );
};
