import { FaDiscord, FaTwitter } from "react-icons/fa";

const FooterLeft = () => {
  const icon_size = 22;

  return (
    <div className="fixed left-6 bottom-9 text-white slideInFromLeft">
      <div className="flex flex-row space-x-1">
        <FaDiscord
          size={icon_size}
          className="hover:scale-[1.10] duration-300"
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://discord.gg/eM2gUrsQFA")}
        />
        <FaTwitter
          size={icon_size}
          className="hover:scale-[1.10] duration-300"
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://twitter.com/wyd_ai")}
        />
      </div>
    </div>
  );
};

export default FooterLeft;
