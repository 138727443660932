import { useEffect, useState } from "react";
import "./loader.css";

const Loader = ({ showMessages }) => {
  const loadingMessages = [
    "Summoning conversational witticisms...",
    "Rehearsing our lines...",
    "Baking fresh responses, just for you...",
    "Doing some vocal warm-ups...",
    "Feeding the hamsters that power our servers...",
    "Tuning the orchestra for our next symphony of words...",
    "Inventing a new language, just in case...",
    "Riding the conversation roller coaster...",
    "Testing the gravity of our words...",
    "Sharpening our linguistic skills...",
    "Running the conversation obstacle course...",
    "Spinning the wheel of fortune for the best response...",
    "Performing an interpretive dance to your query...",
    "Staging a dramatic reading of your message...",
    "Hunting for the perfect metaphor...",
    "Brewing a fresh pot of chat...",
    "Playing hide and seek with the right words...",
    "Mixing a cocktail of responses...",
    "Asking the oracle for the best comeback...",
    "Carving your response on a digital tablet...",
    "Whispering your question to the wind...",
    "Crafting a response worthy of Shakespeare...",
    "Wrestling with words...",
    "Running with the metaphorical scissors...",
    "Weaving a tapestry of talk...",
    "Rolling out the red carpet for your response...",
    "Mining for linguistic gems...",
    "Taking the scenic route to your answer...",
    "Popping kernels of conversation...",
    "Juggling words and phrases...",
  ];

  const [loadingText, _loadingText] = useState(
    loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
  );
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!showMessages) {
        _loadingText("");
        return;
      }
      let randomChoice = loadingText;
      while (randomChoice === loadingText) {
        randomChoice =
          loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
      }
      _loadingText(randomChoice);
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="loader" />
      <p
        key={loadingText}
        style={{
          color: "rgba(255,255,255,0.6)",
          fontSize: "20px",
          animation: "fadeInOut 10s",
          marginTop: "10px",
        }}
      >
        {loadingText}
      </p>
    </div>
  );
};

export default Loader;
