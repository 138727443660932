import { useRef, useState } from "react";
import { DigitInput } from "./DigitInput";
import "./styles.css";

export const VerificationCodeInput = ({ setUserInput, styles }) => {
  const [codeDigits, setCodeDigits] = useState(["", "", "", ""]);
  const [inputRef0, focusInput0] = useFocus();
  const [inputRef1, focusInput1] = useFocus();
  const [inputRef2, focusInput2] = useFocus();
  const [inputRef3, focusInput3] = useFocus();

  const setCodeDigitIndex = (idx, val) => {
    setCodeDigits([
      ...codeDigits.slice(0, idx),
      val,
      ...codeDigits.slice(idx + 1),
    ]);
    setUserInput(
      codeDigits.slice(0, idx).join("") +
        val +
        codeDigits.slice(idx + 1).join("")
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        lineHeight: "1.75em",
        ...styles,
      }}
    >
      <DigitInput
        refObj={inputRef0}
        setPhoneDigit={(val) => setCodeDigitIndex(0, val)}
        focusNext={focusInput1}
        focusPrior={() => {}}
        autoFocus
        style={{ marginLeft: "0px" }}
      />
      <DigitInput
        refObj={inputRef1}
        setPhoneDigit={(val) => setCodeDigitIndex(1, val)}
        focusNext={focusInput2}
        focusPrior={focusInput0}
      />
      <DigitInput
        refObj={inputRef2}
        setPhoneDigit={(val) => setCodeDigitIndex(2, val)}
        focusNext={focusInput3}
        focusPrior={focusInput1}
      />
      <DigitInput
        refObj={inputRef3}
        setPhoneDigit={(val) => setCodeDigitIndex(3, val)}
        focusNext={() => {}}
        focusPrior={focusInput2}
      />
    </div>
  );
};

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
