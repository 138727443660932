import { useSubmitUserQuery } from "./useSubmitUserQuery";
import Loader from "../Loader/Loader";
import { BsMic } from "react-icons/bs";
import { useState } from "react";

const LetsTalkBtnDesktop = ({
  userQuery,
  navigate,
  setUserQuery,
  sourceBufferRef,
  mediaSource,
  loading,
  setLoading,
  recordVoice,
  recordingSupported,
  isPlaying,
  cancelRecording,
  finishRecording,
  userTranscript,
}) => {
  const [isRecordingVoice, _isRecordingVoice] = useState(false);
  const { handleSubmitUserQuery } = useSubmitUserQuery({
    userQuery,
    setLoading,
    navigate,
    sourceBufferRef,
    mediaSource,
    userTranscript,
    isVoiceRecorded: isRecordingVoice,
  });

  return (
    <>
      {loading && <Loader showMessages={!isPlaying} />}
      {!loading && (
        <div className="flex flex-col items-center">
          {!isRecordingVoice && (
            <>
              <input
                className="border-b-2 border-white border-solid text-white text-2xl bg-transparent outline-none text-center duration-100 box-border max-w-full"
                style={{ animation: "fadeInAnimation ease 3s" }}
                autoFocus
                aria-multiline="true"
                onChange={(e) => {
                  e.target.style.width = "auto";
                  e.target.style.width = e.target.scrollWidth + "px";
                  setUserQuery(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter") {
                    handleSubmitUserQuery();
                  }
                }}
              />
              {}
              {recordingSupported() && userQuery.length === 0 && (
                <BsMic
                  size={25}
                  color="white"
                  style={{
                    marginTop: "20px",
                    cursor: "pointer",
                    animation: "fadeInAnimation ease 3s",
                  }}
                  onClick={() => {
                    _isRecordingVoice(true);
                    recordVoice();
                  }}
                />
              )}
              {userQuery.length > 0 && (
                <button
                  className="submitButton"
                  onClickCapture={handleSubmitUserQuery}
                  style={{ display: userQuery ? "flex" : "none" }}
                >
                  Let's Talk
                </button>
              )}
            </>
          )}
          {isRecordingVoice && (
            <>
              <p style={{ color: "white", fontSize: "x-large" }}>
                Say anything that's on your mind
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <button
                  className="submitButton"
                  onClickCapture={async () => {
                    if (userTranscript.length === 0) return;
                    await finishRecording();
                    _isRecordingVoice(false);
                    handleSubmitUserQuery();
                  }}
                >
                  Send
                </button>
                <button
                  className="submitButton"
                  onClickCapture={() => {
                    _isRecordingVoice(false);
                    cancelRecording();
                  }}
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default LetsTalkBtnDesktop;
