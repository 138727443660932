import { useRef, useState } from "react";
import { DigitInput } from "./DigitInput";
import "./styles.css";

export const PhoneInput = ({ setUserInput, styles }) => {
  const [phoneDigits, setPhoneDigits] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [inputRef0, focusInput0] = useFocus();
  const [inputRef1, focusInput1] = useFocus();
  const [inputRef2, focusInput2] = useFocus();
  const [inputRef3, focusInput3] = useFocus();
  const [inputRef4, focusInput4] = useFocus();
  const [inputRef5, focusInput5] = useFocus();
  const [inputRef6, focusInput6] = useFocus();
  const [inputRef7, focusInput7] = useFocus();
  const [inputRef8, focusInput8] = useFocus();
  const [inputRef9, focusInput9] = useFocus();

  const setPhoneDigitIndex = (idx, val) => {
    setPhoneDigits([
      ...phoneDigits.slice(0, idx),
      val,
      ...phoneDigits.slice(idx + 1),
    ]);
    setUserInput(
      phoneDigits.slice(0, idx).join("") +
        val +
        phoneDigits.slice(idx + 1).join("")
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingRight: "30px",
        lineHeight: "1.75em",
        ...styles,
      }}
    >
      <p
        className="subtitle"
        style={{
          margin: "0px",
          fontSize: "1.5em",
          fontWeight: 400,
        }}
      >
        +1 (
      </p>
      <DigitInput
        refObj={inputRef0}
        setPhoneDigit={(val) => setPhoneDigitIndex(0, val)}
        focusNext={focusInput1}
        focusPrior={() => {}}
        autoFocus
        style={{ marginLeft: "5px" }}
      />
      <DigitInput
        refObj={inputRef1}
        setPhoneDigit={(val) => setPhoneDigitIndex(1, val)}
        focusNext={focusInput2}
        focusPrior={focusInput0}
      />
      <DigitInput
        refObj={inputRef2}
        setPhoneDigit={(val) => setPhoneDigitIndex(2, val)}
        focusNext={focusInput3}
        focusPrior={focusInput1}
        style={{ marginRight: "5px" }}
      />
      <p
        className="subtitle"
        style={{
          margin: "0px",
          fontSize: "1.5em",
        }}
      >
        )
      </p>
      <DigitInput
        refObj={inputRef3}
        setPhoneDigit={(val) => setPhoneDigitIndex(3, val)}
        focusNext={focusInput4}
        focusPrior={focusInput2}
        style={{
          marginLeft: "8px",
        }}
      />
      <DigitInput
        refObj={inputRef4}
        setPhoneDigit={(val) => setPhoneDigitIndex(4, val)}
        focusNext={focusInput5}
        focusPrior={focusInput3}
      />
      <DigitInput
        refObj={inputRef5}
        setPhoneDigit={(val) => setPhoneDigitIndex(5, val)}
        focusNext={focusInput6}
        focusPrior={focusInput4}
      />
      <p
        className="subtitle"
        style={{
          margin: "0px 0px 0px 5px",
          justifySelf: "center",
          fontSize: "1.5em",
        }}
      >
        -
      </p>
      <DigitInput
        refObj={inputRef6}
        setPhoneDigit={(val) => setPhoneDigitIndex(6, val)}
        focusNext={focusInput7}
        focusPrior={focusInput5}
      />
      <DigitInput
        refObj={inputRef7}
        setPhoneDigit={(val) => setPhoneDigitIndex(7, val)}
        focusNext={focusInput8}
        focusPrior={focusInput6}
        style={{ marginLeft: "5px" }}
      />
      <DigitInput
        refObj={inputRef8}
        setPhoneDigit={(val) => setPhoneDigitIndex(8, val)}
        focusNext={focusInput9}
        focusPrior={focusInput7}
      />
      <DigitInput
        refObj={inputRef9}
        setPhoneDigit={(val) => setPhoneDigitIndex(9, val)}
        focusNext={() => {}}
        focusPrior={focusInput8}
      />
    </div>
  );
};

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
