import { addToMemory } from "../../hooks/addToMemory";
import { useGlobalState } from "../../state/state";

export const useSubmitUserQuery = (props) => {
  const [authToken] = useGlobalState("authToken");
  const [current_voice] = useGlobalState("current_voice");
  const [current_agent] = useGlobalState("current_agent");
  const [handleGetAccount] = useGlobalState("handleGetAccount");
  const [, _show_no_more_chats] = useGlobalState("show_no_more_chats");
  const [account] = useGlobalState("account");

  const {
    userQuery,
    setLoading,
    sourceBufferRef,
    mediaSource,
    userTranscript,
    isVoiceRecorded,
  } = props;

  const handleSubmitUserQuery = async () => {
    if (!isVoiceRecorded && !userQuery.trim()) return;
    if (isVoiceRecorded && !userTranscript.trim()) return;
    if (account?.subscription?.chat_tokens === 0) {
      _show_no_more_chats(true);
      return;
    }
    setLoading(true);
    const userInput = isVoiceRecorded ? userTranscript.trim() : userQuery;

    let url =
      "https://uekilqvn4ypaygdvhinwfqgvqy0umdgf.lambda-url.us-west-1.on.aws/";
    if (current_agent === "girlfriend") {
      url =
        "https://qh2rz2jztirzpxkiyb3ei7kvte0fkgtv.lambda-url.us-west-1.on.aws/";
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: authToken,
      },
      body: JSON.stringify({
        chat_type: "standard",
        voice_type: current_voice,
        user_query: userInput,
      }),
    });

    let waitingCount = 0;
    const reader = response.body.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        while (waitingCount > 0 || sourceBufferRef.current.updating) {
          await new Promise((r) => setTimeout(r, 100));
        }
        handleGetAccount();
        mediaSource.endOfStream();
        break;
      }
      let waitForUpdate = false;
      if (sourceBufferRef.current.updating) {
        waitingCount++;
        waitForUpdate = true;
      }
      while (sourceBufferRef.current.updating) {
        await new Promise((r) => setTimeout(r, 100));
      }
      if (waitForUpdate) waitingCount--;
      sourceBufferRef.current.appendBuffer(value);
    }
  };

  return { handleSubmitUserQuery };
};
