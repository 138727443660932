import { useEffect, useState } from "react";

const useMouseWiggle = () => {
    const [transformStyle, setTransformStyle] = useState(null);

    useEffect(() => {
        const onMouseMove = (event) => {
            const x = event.clientX;
            const y = event.clientY;
            const offsetX = x - window.innerWidth / 2;
            const offsetY = y - window.innerHeight / 2;
            setTransformStyle(`translate(${-offsetX / 100}px, ${-offsetY / 100}px)`);
        };

        window.addEventListener('mousemove', onMouseMove);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
        };
    }, []);

    if (window.innerWidth >= 768) {
        return transformStyle;
    } else {
        return null;
    }
}

export default useMouseWiggle;