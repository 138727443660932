import { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import HeyBtnDesktop from "../components/Chat-Desktop/HeyBtnDesktop";
import LetsTalkBtnDesktop from "../components/Chat-Desktop/LetsTalkBtnDesktop";
// import HeyBtnMobile from "../components/Chat-Mobile/HeyBtnMobile";
// import LetsTalkBtnMobile from "../components/Chat-Mobile/LetsTalkBtnMobile";
import FooterLeft from "../components/FooterLeft/FooterLeft";
import FooterRight from "../components/FooterRight/FooterRight";
import GlobalAlert from "../components/GlobalAlert/AlertModal";
import { handleGetChatHistory } from "../hooks/handleGetChatHistory";
import useMouseWiggle from "../hooks/useMouseWiggle";
import { useGlobalState } from "../state/state";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

export const Chat = ({ globals, authToken, account, setProfileVisible }) => {
  const { handleLogOut } = globals;
  const navigate = useNavigate();
  const [userQuery, setUserQuery] = useState("");
  const [chatMessages, setChatMessages] = useGlobalState("chatMessages");
  const [showing_profile] = useGlobalState("showing_profile");
  const [showing_about] = useGlobalState("showing_about");
  const [current_agent] = useGlobalState("current_agent");
  const [, _show_no_more_chats] = useGlobalState("show_no_more_chats");
  const [hasBeenGreeted, setHasBeenGreeted] = useState(false);
  const [showing_volume_prompt, set_showing_volume_prompt] = useState(true);
  const [showing_desktop_prompt, set_showing_desktop_prompt] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mediaSource, setMediaSource] = useState(null);
  const audioRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const [isPlaying, _isPlaying] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();

  useEffect(() => {
    const ms = new MediaSource();
    setMediaSource(ms);
    ms.addEventListener("sourceopen", () => {
      const sourceBuffer = ms.addSourceBuffer("audio/mpeg");
      sourceBufferRef.current = sourceBuffer;
    });
    return () => {
      if (mediaSource) {
        mediaSource.removeEventListener("sourceopen", () => {});
      }
    };
  }, []);

  useEffect(() => {
    if (audioRef.current && mediaSource) {
      audioRef.current.src = URL.createObjectURL(mediaSource);
      audioRef.current.addEventListener("canplay", (e) => {
        resetTranscript();
        _isPlaying(true);
        audioRef.current.play();
      });
      audioRef.current.addEventListener("ended", () => {
        _isPlaying(false);
        setLoading(false);
        setHasBeenGreeted(true); //Check if its ok to do this every time
        const ms = new MediaSource();
        setMediaSource(ms);
        ms.addEventListener("sourceopen", () => {
          const sourceBuffer = ms.addSourceBuffer("audio/mpeg");
          sourceBufferRef.current = sourceBuffer;
        });
      });
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("canplay", () => {});
        audioRef.current.removeEventListener("ended", () => {});
      }
    };
  }, [mediaSource]);

  useEffect(() => {
    if (authToken === null) {
      navigate("/");
    }
    if (authToken !== undefined && chatMessages === undefined) {
      handleGetChatHistory(authToken, handleLogOut, navigate);
    }
  });

  useEffect(() => {
    setHasBeenGreeted(false);
    setChatMessages([]);
  }, [current_agent]);

  const recordVoice = async () => {
    await SpeechRecognition.startListening({
      continuous: true,
      language: "en-US",
    });
  };

  const recordingSupported = () => {
    return SpeechRecognition.browserSupportsSpeechRecognition();
  };

  const handleFinishRecording = async () => {
    await SpeechRecognition.stopListening();
  };

  const handleCancelRecording = async () => {
    await SpeechRecognition.stopListening();
    resetTranscript();
  };

  const LetsTalkBtn = (
    <>
      <BrowserView>
        <LetsTalkBtnDesktop
          navigate={navigate}
          userQuery={userQuery}
          setUserQuery={setUserQuery}
          sourceBufferRef={sourceBufferRef}
          mediaSource={mediaSource}
          loading={loading}
          setLoading={setLoading}
          recordVoice={recordVoice}
          recordingSupported={recordingSupported}
          isPlaying={isPlaying}
          finishRecording={handleFinishRecording}
          cancelRecording={handleCancelRecording}
          userTranscript={transcript}
        />
      </BrowserView>
      {/* <MobileView>
        <LetsTalkBtnMobile
          navigate={navigate}
          userQuery={userQuery}
          setUserQuery={setUserQuery}
        />
      </MobileView> */}
    </>
  );

  const HeyBtn = !showing_profile && !showing_about && (
    <>
      <BrowserView>
        <HeyBtnDesktop
          navigate={navigate}
          setHasBeenGreeted={setHasBeenGreeted}
          sourceBufferRef={sourceBufferRef}
          mediaSource={mediaSource}
          loading={loading}
          setLoading={setLoading}
          isPlaying={isPlaying}
        />
      </BrowserView>
      {/* <MobileView>
        <HeyBtnMobile
          navigate={navigate}
          setHasBeenGreeted={setHasBeenGreeted}
        />
      </MobileView> */}
    </>
  );

  const show_hey_btn = chatMessages !== undefined && !hasBeenGreeted;

  return (
    <>
      <div
        style={{ transform: useMouseWiggle() }}
        className="flex flex-col items-center justify-center h-screen"
      >
        <div className="flex flex-col items-center">
          {show_hey_btn && HeyBtn}
          {hasBeenGreeted && LetsTalkBtn}
          <audio style={{ visibility: "hidden" }} ref={audioRef} />
        </div>
      </div>
      <FooterLeft />
      <FooterRight
        total={
          account?.subscription.renew_tokens > 0
            ? account?.subscription.renew_tokens
            : 100
        }
        remaining={account?.subscription.chat_tokens}
        setProfileVisible={setProfileVisible}
      />
      <GlobalAlert
        title="It's a little quiet in here 🔈"
        subtitle="Make sure your volume is turned up"
        should_display={
          (isMobile && !showing_desktop_prompt && showing_volume_prompt) ||
          (!isMobile && showing_volume_prompt)
        }
        dismiss_title={"Got it"}
        on_dismiss={() => set_showing_volume_prompt(false)}
      />
      <GlobalAlert
        title="WYD.AI is currently in desktop beta"
        subtitle="Mobile support coming soon!"
        should_display={isMobile && showing_desktop_prompt}
        dismiss_title={"Got it"}
        on_dismiss={() => set_showing_desktop_prompt(false)}
      />
    </>
  );
};
