export const TextInput = ({ setValue, onEnter }) => {
  return (
    <input
      className="border-b-2 border-white border-solid text-white text-xl bg-transparent outline-none text-center duration-100 box-border max-w-full"
      style={{
        animation: "fadeInAnimation ease 3s",
      }}
      autoFocus
      aria-multiline="true"
      onChange={(e) => {
        e.target.style.width = "auto";
        e.target.style.width = e.target.scrollWidth + "px";
        setValue(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
          onEnter();
        }
      }}
    />
  );
};
