import { getChatHistory } from "./getChatHistory";

export const handleGetChatHistory = async (authToken, handleLogOut, navigate) => {
    const res = await getChatHistory(authToken);
    if (res.error) {
        if (res.body === "Invalid authorization") {
            handleLogOut(navigate);
        } else {
            alert("Error getting chat history");
        }
        return;
    }
    return res.body.chat_history;
};