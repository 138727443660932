import { useGlobalState } from "../../state/state";
import Modal from "../Modal/Modal";

const GlobalAlert = ({
  title,
  subtitle,
  should_display,
  dismiss_title,
  on_dismiss,
}) => {
  return (
    <>
      {should_display && (
        <div className="h-screen w-screen bg-black bg-opacity-20 slideInFromLeft absolute top-0" />
      )}
      <Modal visible={should_display}>
        <div className="flex flex-col items-center p-2 fixed">
          <div className="text-center text-white text-lg">{title}</div>
          <div className="text-center text-gray-400">{subtitle}</div>
          <div
            className="h-12 min-w-full rounded-full border-gray-400 border-[1.5px] mt-6 hover:scale-[1.02] duration-300 text-white font-semibold text-md justify-center items-center flex cursor-pointer"
            onClick={() => on_dismiss()}
          >
            {dismiss_title}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GlobalAlert;
