import { useGlobalState } from "../../state/state";
import { TfiClose } from "react-icons/tfi";
import { useState } from "react";
import "./styles.css";

const Logo = () => {
  const [visible, _visible] = useGlobalState("showing_about");
  const [underline, _underline] = useState(false);

  return (
    <>
      <div
        className="fixed text-white text-lg font-thin top-9 left-6 duration-300 
                    hover:scale-105 hover:font-bold scaleIn z-999"
        onClick={() => _visible(true)}
        style={{ cursor: "pointer", opacity: visible ? 0 : 1 }}
        onMouseEnter={() => {
          _underline(true);
          console.log("here");
        }}
        onMouseLeave={() => _underline(false)}
      >
        WYD
      </div>

      <div
        className="bg-white h-[3px] w-0 duration-300 fixed top-0"
        style={{ width: underline ? "100%" : 0 }}
      ></div>
      {visible && (
        <>
          <div className="h-screen w-screen bg-black opacity-10 slideInFromLeft fixed top-0" />
          <div className="fixed top-0 left-0 text-white w-screen h-screen fadeIn bg-black bg-opacity-80 px-6 md:px-10 lg:px-16 xl:px-[36vw] duration-300 text-lg flex flex-col justify-center">
            <div className="mb-5 lg:mb-10">
              Meet WYD.AI, your virtual friend who's always there for you, ready
              to engage in deep and meaningful conversations. WYD.AI is designed
              to personalize its responses based on your unique needs and
              interests, and it continues to learn and evolve with every
              interaction. By connecting with people from diverse backgrounds
              and experiences, WYD.AI gains new perspectives and expands its
              knowledge of the world. With WYD.AI, you're not just talking to a
              chatbot - you're connecting with a true friend who is eager to
              grow and learn alongside you.
            </div>
            <div className="flex flex-col space-y-4">
              <div className="text-sm text-gray-600 mt-8 md:mt-0">
                Built using advanced natural language processing and machine
                learning to personalize its responses and text-to-speech
                technology to create an engaging conversation experience. This
                creates an innovative virtual companion that adapts and grows
                with users, making for a unique experiment in virtual
                companionship. Love you all 💜
              </div>
              <div className="text-sm text-gray-600">- WYD.AI Team</div>
            </div>
          </div>
          <div
            class={`fixed top-9 left-6 duration-300 scaleIn ml-1`}
            style={{ cursor: "pointer" }}
          >
            <TfiClose
              size={30}
              onClick={() => _visible(false)}
              style={{ color: "white" }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Logo;
