import { useState } from "react";
import "./styles.css";

export const DigitInput = ({
  autoFocus,
  focusNext,
  focusPrior,
  refObj,
  style,
  setPhoneDigit,
}) => {
  const [value, setValue] = useState("");

  return (
    <input
      ref={refObj}
      className="digitInput"
      type='number' 
      inputMode='numeric' 
      pattern="[0-9]*"
      onKeyDown={(e) => {
        if (e.code === "Backspace" && value === "") {
          focusPrior();
          setPhoneDigit("");
        }
        if (e.code === "Backspace" && value.length >= 1) {
          setPhoneDigit("");
          setValue("");
        }
        if (e.code === "ArrowRight") focusNext();
        if (e.code === "ArrowLeft") focusPrior();
      }}
      onInput={(e) => {
        if (e.target.value === "") {
          setValue("");
        } else if (value.length === 1 && e.target.value.length > 1) {
          focusNext();
        } else if (/^[0-9]$/.test(e.target.value)) {
          setPhoneDigit(e.target.value);
          setValue(e.target.value);
          focusNext();
        }
      }}
      style={{
        borderColor: value ? "transparent" : "",
        ...style,
      }}
      value={value}
      autoFocus={autoFocus}
    />
  );
};
